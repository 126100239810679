import { Injectable } from '@angular/core';
import { Observable, distinctUntilChanged, filter, map } from 'rxjs';

import { CompanyDetailModel } from '@dps/shared/models';
import { Store } from './store';

interface RootStoreState {
  currentCompany: CompanyDetailModel | null;
  currCompanyActualsCount: number;
}

@Injectable({ providedIn: 'root' })
export class RootStore extends Store<RootStoreState> {
  constructor() {
    super({ currentCompany: null, currCompanyActualsCount: 0 });
  }

  setCompany(company: CompanyDetailModel | null): void {
    this.update({ currentCompany: company });
  }

  getCompanyData$(): Observable<CompanyDetailModel> {
    return this.select$(state => state.currentCompany).pipe(filter(Boolean));
  }

  getCurrCompanyActualsCount$(): Observable<number> {
    return this.select$(state => state.currCompanyActualsCount);
  }

  setCurrCompanyActualsCount(count: number): void {
    return this.update({ currCompanyActualsCount: count });
  }

  isCompanyTimeRegistrationEnabled$(): Observable<boolean> {
    return this.getCompanyData$().pipe(
      map(company => company.isTimeRegistrationEnabled),
      distinctUntilChanged()
    );
  }

  isCompanyGroupsEnabled$(): Observable<boolean> {
    return this.getCompanyData$().pipe(
      map(company => company.isGroupsEnabled),
      distinctUntilChanged()
    );
  }
}

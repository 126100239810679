import { MinMaxRange } from '../types/types';

export const EMPLOYEE_GROSS_HOUR_WAGE_RANGE: MinMaxRange = [8.5, 100];
export const MEAL_VOUCHERS_TOTAL_RANGE: MinMaxRange = [2.18, 8];
export const MEAL_VOUCHERS_EMPLOYEE_MIN: number = 1.09;

export const CONSTRUCTION_PC_CODE: string = '124';

export const DEFAULT_REVENUE_OFFICE_CODE: string = 'DPS100';
export const CONSTRUCTION_WAGE_REVENUE_OFFICE_CODE: string = 'DPS200';

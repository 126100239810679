import { CurrentUserModel, UserRole } from '@dps/shared/models';
import { Store } from './store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs';

interface AuthState {
  currentUser: CurrentUserModel | null;
}

@Injectable({ providedIn: 'root' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super({ currentUser: null });
  }

  setCurrentUser(currentUser: CurrentUserModel): void {
    this.update({ currentUser });
  }

  getCurrUserData$() {
    return this.select$(state => state.currentUser).pipe(filter(Boolean));
  }

  hasRoles(desiredRoles: UserRole[]): boolean {
    return desiredRoles.some(desiredRole =>
      this.get().currentUser?.userRoles.includes(desiredRole)
    );
  }
}

import { Duration } from 'luxon';

export const DEFAULT_CONTRACT_DURATION = Duration.fromObject({ hours: 9 });

export const MIN_CONTRACT_DURATION = Duration.fromObject({ hours: 3 });

export const MIN_CONTRACT_DURATION_PER_PC_CODE: Record<string, Duration> = {
  '302': Duration.fromObject({ hours: 2 }),
  '314.03': Duration.fromObject({ hours: 1 }),
};

export const MAX_CONTRACT_DURATION = Duration.fromObject({ hours: 16 });

export const MIN_SPAN_TO_START_TODAY_CONTRACT = Duration.fromObject({ minutes: 30 });
export const MIN_SPAN_TO_CANCEL_TODAY_CONTRACT = Duration.fromObject({ minutes: 15 });

export const MAX_EMPLOYEE_CONTRACTS_PER_WEEK = 7;

import { StatuteCodeEnum } from '../models';

export const BELGIUM_COUNTRY_CODE = 'BE';

export const WORKER_STUDENT_STATUTE_CODES: Array<Partial<StatuteCodeEnum>> = [
  StatuteCodeEnum.LABOUR_STUDENT_WORKER,
  StatuteCodeEnum.WHITE_COLLAR_STUDENT_WORKER,
];

export const BYTES_IN_KILOBYTE = 1000;
